
















































































import './styles/TaskRow.scss';
import './styles/TaskRowAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IUserState } from '@store/modules/user/Interfaces';
import { ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { FullNameFormatter } from '@utility/FullNameFormatter';

const NSUser = namespace('storeUser');

@Component({
    name: 'TaskRow',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseSelect: () => import('@components/BaseSelect'),
    },
})
export default class TaskRow extends Vue {
    @Prop({}) task!: ITask;
    @Prop({}) isOnTop!: boolean;

    emitShowTaskDetails() {
        this.$emit('show-task-details');
    }

    @NSUser.State((state: IUserState) => state.userInfo.id) userUuid!: string;

    get executedByUser() {
        return this.task.executor?.uuid === this.userUuid;
    }

    get createByUser() {
        return this.task.author.uuid === this.userUuid;
    }

    get isWorkInProgressStatus() {
        return this.task.state.name === 'На проверке';
    }

    get shortAuthorName() {
        return new FullNameFormatter(this.task.author.fullName).getShortName();
    }

    get shortExecutorName() {
        return this.task.executor ? new FullNameFormatter(this.task.executor.fullName).getShortName() : null;
    }

    get creationDateString(): string {
        return new Intl.DateTimeFormat([], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .format(new Date(this.task.creationDate));
    }

    get deadlineString(): string {
        return new Intl.DateTimeFormat([], {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        })
        .format(new Date(this.task.deadline));
    }

    get isDeadlineOver(): boolean {
        const taskDeadlineMs = new Date(this.task.deadline).getTime();
        const dateNowMs = Date.now();
        return taskDeadlineMs < dateNowMs;
    }
}
